import React, { useState } from 'react';
import styled from 'styled-components';
import Product from '../Product';
import Filter from '../Filter';
import TextField from '../TextField';

const Container = styled.section`
  height: ${props => props.currentHeight}px;
  overflow: hidden;
  transition: height 0.3s linear;
  background: ${props => props.theme.backgroundColors.main};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: ${props => props.theme.space.sectionSpace} 0;
  margin-top: 0;
`;

const Readmore = styled.button`
  display: block;
  margin: 0 auto;
  padding: 12px 18px;
  color: #fff;
  border-radius: 8px;
  transition: background 0.1s ease-in;
  font-weight: bold;
  background: ${props => props.theme.themeColor.primary};
  border: 0;
  color: #fff;
  cursor: pointer;
  outline: none;
`;

const Notfound = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32px;

  h3 {
    margin: 12px 0;
  }
`;

function Products({ type, data }) {
  const [filteredProducts, setFiltered] = useState([...data]);
  const [slice, setSlice] = useState(20);

  if (!filteredProducts.length) {
    return (
      <>
        <Filter
          type={type}
          products={filteredProducts}
          setFilter={setFiltered}
          unfiltered={[...data]}
        />
        <Notfound>
          <h3>Ingen vin fundet</h3>
        </Notfound>
        <TextField />
      </>
    );
  }

  return (
    <>
      <Filter
        type={type}
        unfiltered={[...data]}
        products={filteredProducts}
        setFilter={setFiltered}
      />
      <Container>
        {filteredProducts.slice(0, slice).map(product => (
          <Product
            key={product.productId}
            path={product.productPath}
            link={product.productLink}
            title={product.productTitle}
            description={product.productDescription}
            price={product.productPrice}
            image={product.productImage}
            offer={product.productOldprice}
            productId={product.productId}
            available={product.productAvailable}
            type={product.productType}
          />
        ))}
      </Container>
      {slice < filteredProducts.length && (
        <Readmore onClick={() => setSlice(previous => previous + 20)}>
          Vis flere
        </Readmore>
      )}
    </>
  );
}

export default Products;
