import React, { useState } from 'react';
import styled from 'styled-components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { device } from '../../providers/theme';

const Container = styled.section`
  margin: ${props => props.theme.space.sectionSpace} 0;
  padding: 22px;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'inherit')};

  @media ${device.mobile} {
    padding: 0 12px;
  }
`;

const HeaderTitle = styled.h2`
  margin-top: 8px;
  font-size: 40px;
`;

const Title = styled.h3`
  margin-top: 8px;
  font-size: 1.5rem;
`;

const TextContainer = styled.div`
  position: relative;
  // height: ${props => (props.open === false ? '63px' : '320px')};
  overflow: hidden;
  transition: height 0.3s;
`;

const Text = styled.p`
  display: block;
  font-size: 20px;
  margin: 0;
  line-height: 1.8;
`;

const Shadow = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 25px;
  width: 100%;
  background: #fff;
  opacity: 0.7;
`;

const ToggleOpen = styled.button`
  display: block;
  cursor: pointer;
  margin: 12px auto;
  border: none;
  outline: none;
`;

function InfoContainer({ title, text, withToggle, header, maxWidth }) {
  const [isOpen, setOpen] = useState(false);

  if (header) {
    return (
      <Container maxWidth={maxWidth}>
        <HeaderTitle>{title}</HeaderTitle>
        <TextContainer>
          <Text>{text}</Text>
        </TextContainer>
      </Container>
    );
  }

  if (withToggle) {
    return (
      <Container>
        <Title>{title}</Title>
        <TextContainer open={isOpen}>
          <Text>{text}</Text>
          {!isOpen && <Shadow />}
        </TextContainer>
        <ToggleOpen onClick={() => setOpen(!isOpen)}>
          {isOpen ? <IoIosArrowUp size={42} /> : <IoIosArrowDown size={42} />}
        </ToggleOpen>
      </Container>
    );
  }

  return (
    <Container>
      <Title>{title}</Title>
      <TextContainer>
        <Text>{text}</Text>
      </TextContainer>
    </Container>
  );
}

export default InfoContainer;
