import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { device } from '../../providers/theme';

const Container = styled.div`
  text-align: right;
  padding: 0 22px;
`;

const StyledLink = styled(Link)`
  font-size: 13px;
  margin: 0;
  font-weight: 100;
`;

const UL = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
`;

const LI = styled.li`
  font-size: 13px;
  margin: 0;
  font-weight: 100;
  &.space {
    margin: 0 8px;
  }

  @media ${device.mobile} {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

function Breadcrumbs({ currentWine, type }) {
  const handleType = () => {
    switch (type) {
      case 'roedvin':
        return { url: 'roedvin', name: 'Rødvin' };
      case 'hvidvin':
        return { url: 'hvidvin', name: 'Hvidvin' };
      case 'rose':
        return { url: 'rose', name: 'Rosé' };
      case 'champagne':
        return { url: 'champagne', name: 'Champagne' };
      case 'mousserende':
        return { url: 'mousserende', name: 'Mousserende' };
      case 'portvin':
        return { url: 'portvin', name: 'Portvin' };
      case 'dessertvin':
        return { url: 'dessertvin', name: 'Dessertvin' };
      case 'andet':
        return { url: 'andet', name: 'Andet' };
      default:
        return { url: '', name: '' };
    }
  };

  return (
    <Container>
      <UL>
        <LI>
          <StyledLink to="/">Forside</StyledLink>
        </LI>
        <LI className="space">/</LI>
        <LI>
          {currentWine ? (
            <StyledLink to={`/${handleType().url}`}>
              {handleType().name}
            </StyledLink>
          ) : (
            <LI>{handleType().name}</LI>
          )}
        </LI>
        {currentWine && (
          <>
            <LI className="space">/</LI>
            <LI>{currentWine}</LI>
          </>
        )}
      </UL>
    </Container>
  );
}

export default Breadcrumbs;
