export const handleProducts = products => {
  return products.allSitePage.edges.map(e => ({
    productPath: e.node.path,
    productTitle: e.node.context.productTitle?.[0],
    productPrice: e.node.context.productPrice?.[0],
    productOldprice: e.node.context.productOldprice?.[0],
    productLink: e.node.context.productLink?.[0],
    productImage: e.node.context.productImage?.[0],
    productDescription: e.node.context.productDescription?.[0],
    productCategory: e.node.context.productCategory?.[0],
    productAvailable: e.node.context.productAvailable?.[0],
    productType: e.node.context.productType?.[0]
  }));
};
