import React, { useState } from 'react';
import styled from 'styled-components';
import { device } from '../../providers/theme';

const Container = styled.div`
  padding: 0 32px;
  margin-top: 12px;

  @media ${device.mobile} {
    padding: 0 12px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Form = styled.form`
  margin: 0;
`;

const SearchField = styled.div`
  position: relative;
  background: transparent;
  border: 2px solid #e2e2e2;
  border-radius: 8px;
  padding: 8px 38px 8px 12px;
  min-width: 460px;
  margin-right: 8px;

  @media ${device.mobile} {
    min-width: inherit;
    margin-right: 0;
    width: 100%;
  }
`;

const Input = styled.input`
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
`;

const ClearInput = styled.button`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.themeColor.primary};
  border-radius: 50%;
  color: #fff;
  outline: none;
  line-height: 1;
  cursor: pointer;
  font-weight: bold;
  border: 0;
`;

const Buttons = styled.div`
  display: flex;
  @media ${device.tablet} {
    width: 100%;
    margin: 12px 0;
  }
`;

const SearchButton = styled.button`
  background: ${props =>
    props.active ? props.theme.themeColor.primary : 'transparent'};
  padding: 8px 12px;
  border: 2px solid ${props => props.theme.themeColor.primary};
  color: ${props => (props.active ? '#fff' : props.theme.themeColor.primary)};
  margin: 0 8px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  font-weight: bold;

  @media ${device.tablet} {
    width: 33.333%;
    padding: 8px 8px;
  }
  @media ${device.mobile} {
    margin: 0 2px;
  }
`;

function Filter({ type, products, setFilter, unfiltered }) {
  const [active, setActive] = useState('standard');
  const [input, setInput] = useState('');

  const handleInput = e => {
    e.preventDefault();

    const { value } = e.target;
    setInput(value);

    const filter = [...unfiltered].filter(product =>
      product.productTitle.toLowerCase().match(value.toLowerCase())
    );

    if (active === 'lowest') {
      const lowestToHighest = filter.sort(
        (a, b) => a.productPrice - b.productPrice
      );
      setFilter([...lowestToHighest]);
      return;
    }

    if (active === 'highest') {
      const highestToLowest = filter.sort(
        (a, b) => b.productPrice - a.productPrice
      );
      setFilter([...highestToLowest]);
      return;
    }

    setFilter([...filter]);
  };

  const clearInput = () => {
    setInput('');

    if (active === 'lowest') {
      const lowestToHighest = [...unfiltered].sort(
        (a, b) => a.productPrice - b.productPrice
      );
      setFilter([...lowestToHighest]);
      return;
    }

    if (active === 'highest') {
      const highestToLowest = [...unfiltered].sort(
        (a, b) => b.productPrice - a.productPrice
      );
      setFilter([...highestToLowest]);
      return;
    }

    setFilter([...unfiltered]);
  };

  const handleLowest = () => {
    setActive('lowest');
    const copy = [...products];
    const lowestToHighest = copy.sort(
      (a, b) => a.productPrice - b.productPrice
    );

    setFilter([...lowestToHighest]);
  };

  const handleHighest = () => {
    setActive('highest');
    const copy = [...products];
    const highestToLowest = copy.sort(
      (a, b) => b.productPrice - a.productPrice
    );
    setFilter([...highestToLowest]);
  };

  const handleRelevans = () => {
    setActive('standard');
    if (input.length) {
      setFilter([...products]);
    } else {
      setFilter([...unfiltered]);
    }
  };

  const displayType = (type) => {
    if (type === 'dessert') {
      return 'dessertvin'
    }

    if (type === 'mousserende') {
      return 'mousserende vin'
    }

    return type
  }

  return (
    <Container>
      <FilterContainer>
        <SearchField>
          <Form>
            <Input
              type="text"
              value={input}
              onChange={e => handleInput(e)}
              placeholder={`Søg efter ${displayType(type)}`}
            />
          </Form>
          {input !== '' && (
            <ClearInput onClick={() => clearInput()}>x</ClearInput>
          )}
        </SearchField>
        <Buttons>
          <SearchButton
            active={active === 'standard'}
            onClick={() => handleRelevans()}
          >
            Standard
          </SearchButton>
          <SearchButton
            active={active === 'lowest'}
            onClick={() => handleLowest()}
          >
            Laveste pris
          </SearchButton>
          <SearchButton
            active={active === 'highest'}
            onClick={() => handleHighest()}
          >
            Højeste pris
          </SearchButton>
        </Buttons>
      </FilterContainer>
    </Container>
  );
}

export default Filter;
