import React from 'react';
import styled from 'styled-components';
import { device } from '../../providers/theme';

const Container = styled.div`
  margin: 62px 0;
  padding: 0 22px;

  @media ${device.mobile} {
    padding: 0;
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  margin: 0;
  padding: 12px 0;
`;

const Text = styled.p`
  margin: 0;
  font-size: 1rem;
  padding: 12px 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const Column = styled.div`
  flex-basis: 48%;
  padding: 22px 0;
  @media ${device.mobile} {
    flex-basis: 100%;
  }
`;

function TextField({ column, columnTitle, titleText, descriptionText }) {
  return (
    <Container>
      {!columnTitle && <Title>{titleText}</Title>}
      {column ? (
        <Row>
          <Column>
            {columnTitle && <Title>Lorem Ipsum</Title>}
            tempor cum soluta nobis eleifend option congue nihil imperdiet
            doming id quod mazim placerat facer possim assum. Typi non habent
            claritatem insitam; est usus legentis in iis qui facit eorum
            claritatem. Investigationes demonstraverunt lectores legere me lius
            quod ii legunt saepius. Claritas est etiam processus dynamicus, qui
            sequitur mutationem consuetudium lectorum. Mirum est notare quam
            littera gothica, quam nunc putamus parum claram, anteposuerit
            litterarum formas humanitatis per seacula quarta decima et quinta
            decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant
            sollemnes in futurum.
          </Column>
          <Column>
            {columnTitle && <Title>Lorem Ipsum</Title>}
            tempor cum soluta nobis eleifend option congue nihil imperdiet
            doming id quod mazim placerat facer possim assum. Typi non habent
            claritatem insitam; est usus legentis in iis qui facit eorum
            claritatem. Investigationes demonstraverunt lectores legere me lius
            quod ii legunt saepius. Claritas est etiam processus dynamicus, qui
            sequitur mutationem consuetudium lectorum. Mirum est notare quam
            littera gothica, quam nunc putamus parum claram, anteposuerit
            litterarum formas humanitatis per seacula quarta decima et quinta
            decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant
            sollemnes in futurum.
          </Column>
        </Row>
      ) : (
        <Text>{descriptionText}</Text>
      )}
    </Container>
  );
}

export default TextField;
