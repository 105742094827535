import React from 'react';
import styled from 'styled-components';
import { device } from '../../providers/theme';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${props => props.theme.space.sectionSpace} 0;

  @media ${device.mobile} {
    display: block;
    margin-top: 80px;
  }
`;

const Card = styled.div`
  flex: 1 0 33.333%;
  padding: 12px 22px;

  @media ${device.mobile} {
    flex: auto;
    width: 100%;
    padding: 12px;
  }
`;

const Text = styled.div`
  width: 100%;
`;

const Title = styled.h3`
  text-align: ${props => (props.center ? 'center' : 'left')};
  margin-top: 8px;
  font-size: 1.5rem;
`;

const Icon = styled.div`
  text-align: center;
`;

function InfoCards({ firstColumn, secondColumn, thirdColumn }) {
  return (
    <Container>
      <Card>
        {firstColumn.icon && <Icon>{firstColumn.icon}</Icon>}
        <Text>
          <Title center={firstColumn.icon !== undefined}>
            {firstColumn.title}
          </Title>
          <p>{firstColumn.text}</p>
        </Text>
      </Card>
      <Card>
        {secondColumn.icon && <Icon>{secondColumn.icon}</Icon>}
        <Text>
          <Title center={firstColumn.icon !== undefined}>
            {secondColumn.title}
          </Title>
          <p>{secondColumn.text}</p>
        </Text>
      </Card>
      <Card>
        {thirdColumn.icon && <Icon>{thirdColumn.icon}</Icon>}
        <Text>
          <Title center={firstColumn.icon !== undefined}>
            {thirdColumn.title}
          </Title>
          <p>{thirdColumn.text}</p>
        </Text>
      </Card>
    </Container>
  );
}

export default InfoCards;
