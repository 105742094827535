import React, { useLayoutEffect, useContext } from 'react';
import Layout from '../components/layout';
import { ThemeContext } from '../providers/theme';
import Products from '../components/Products';
import InfoCards from '../components/InfoCard';
import InfoContainer from '../components/InfoContainer';
import SEO from '../components/seo';
import { handleProducts } from '../utils/handleProducts';
import { graphql, useStaticQuery } from 'gatsby';
import Breadcrumbs from '../components/Breadcrumbs';

function Portvin() {
  const { setTheme } = useContext(ThemeContext);

  useLayoutEffect(() => {
    setTheme('dessertWine');
  }, []);

  const data = handleProducts(
    useStaticQuery(graphql`
      {
        allSitePage(
          filter: {
            context: {
              productType: { eq: "portvin" }
              isProduct: { eq: "true" }
            }
          }
        ) {
          edges {
            node {
              path
              context {
                productTitle
                productPrice
                productOldprice
                productLink
                productImage
                productDescription
                productCategory
                productAvailable
                productType
              }
            }
          }
        }
      }
    `)
  );

  return (
    <React.Fragment>
      <SEO
        title={'Portvin'}
        description={
          'Find den helt rigtige portvin. Vi samler udvalg i samarbejde med en håndfuld af landets vinforhandlere, for at gøre det nemt for dig at finde den helt rette flaske'
        }
      />
      <Layout themeColor="#400000" title="Portvin">
        <Products type="portvin" data={data} />
        <InfoCards
          firstColumn={{
            title: 'Portvin til enhver smag',
            text:
              'På vinkammeret.dk kan du finde mange forskellige slags portvine, da det er vigtigt for os at du finder lige præcis den slags, som passer til dig og dine smagsløg. Når du har fundet den helt rigtige portvin, så bliver du sendt videre til forhandleren, hvor du kan købe portvinen. '
          }}
          secondColumn={{
            title: 'Forskellige slags portvine',
            text:
              'Der findes flere forskellige slags portvine fra forskellige årgange, både tørre og meget søde. De mest populære slags portvinen er White Port, Tawny, Colheita, Late bottled vintage (LBV) og Ruby. '
          }}
          thirdColumn={{
            title: 'Portvin fra Portugal',
            text:
              'Portvin produceres i Portugal og er navngivet efter den portugisiske by Porto. Portvinen fremstilles af druer fra området omkring Douro floden, som ligger i det nordlige Portugal. '
          }}
        />
        <InfoContainer
          title={'Vidste du...'}
          text={
            'At portvin bliver produceret ved at man tilsætter brændevin som indeholder 77% alkohol, til vinen, efter den har gæret i kort tid. \n\nNår man stopper gæringen efter så kort tid, så når sukkeret ikke at omdanne sig til alkohol, hvilket betyder at der er ca. 100-150 g restsukker tilbage i vinen. Derefter hælder man vinen op i forskellige størrelser fade, hvor vinen ligger og hviler i nogle måneder, inden de bliver fragtet videre til lagrings husene Vila Nova de Gaia. \n\nEn portvin skal helst serveres afkølet i et hvidvinsglas, gerne 12-15 grader og altså ikke køleskabskold, da det får duften af alkohol frem i næsen. \n\nMan deler portvin op i to typer, enten Ruby eller Tawny. Under typen Ruby, kommer typerne Ruby, LBV og Vintage port. Disse typer er kendetegnet ved at være frugtige og mørke årgangsportvine, som lagres i kortere tid. Under typen Tawny findes typerne Tawny, Tawny med alder og Colheita, som er kendetegnet ved at have en brunlige farve, og lagres mellem 10 og 30 år. \n\nPortvin passer rigtig godt til dessert og kaffe, for eksempel is eller chokolade desserter.'
          }
        />
        <Breadcrumbs type={'portvin'} />
      </Layout>
    </React.Fragment>
  );
}

export default Portvin;
